<template>
<div>
  <router-view/>
</div>
</template>


<script>
import {checkLogin} from '@/plugins/auth'
export default {
  created () {
    this.isLogin()
  },
  data() {
    return {
      active: '',
    }
  },
  methods:{
    isLogin() {
      if (!checkLogin(false)) {
        this.$router.push({
          name: "login"
        })
      }
    }
  }
}
</script>
